import groq from 'groq'

import { cartSettingsFragment } from './cart'
import {
  complexPortableTextFragment,
  simplePortableTextFragment,
} from './content'
import { imageFragment } from './image'
import { linkFragment, linkPageFragment } from './link'
import { productFragment } from './product'

const headerDesktopMenuFragment = groq`
  slug,
  items[] {
    ${linkFragment}
    dropdownItems[] {
      ${linkFragment}
    },
    featured[] {
      link->{
        _type != "product" => {
          _type,
          slug,
          title,
          image {
            ${imageFragment}
          },
          queryString,
        },
        _type == "product" => {
          ${productFragment}
        }
      },
      title,
      image {
        ${imageFragment}
      }
    },
  },
`

const headerMobileMenuFragment = groq`
  slug,
  items[] {
    ${linkFragment}
    dropdownItems[] {
      ${linkFragment}
    },
  },
`

const headerPromoFragment = groq`
  ...,
  link->{
    ${linkPageFragment}
  },
`

const headerFragment = groq`
  menuDesktopLeft->{
    ${headerDesktopMenuFragment}
  },
  menuDesktopRight->{
    ${headerDesktopMenuFragment}
  },
  menuMobilePrimary->{
    ${headerMobileMenuFragment}
  },
  menuMobileSecondary->{
    ${headerMobileMenuFragment}
  },
`

export const newsletterFragment = groq`
  ...,
  successMsg[] {
    ${complexPortableTextFragment}
  },
  errorMsg[] {
    ${complexPortableTextFragment}
  },
  terms[] {
    ${simplePortableTextFragment}
  },
`

const footerFragment = groq`
  ...,
  "blocks": blocks[] {
    _type,
    _key,
    _type == "menu" => @->{
      title,
      items[] {
        ${linkFragment}
      }
    },
    _type == "newsletter" => {
      ${newsletterFragment}
    },
  },
`

const cookieConsentFragment = groq`
  ...,
  link->{
    ${linkPageFragment}
  },
`

const blogStringsFragment = groq`
  blogTitle,
  blogPostReadMore,
  blogRelatedArticlesTitle,
  blogPostByAuthor,
`

const buttonStringsFragment = groq`
  buttonAddToCart,
  buttonRemove,
  buttonAccept,
  buttonLearnMore,
  buttonTryAgain,
  buttonLoadMore,
  buttonUnavailable,
  buttonWaiting,
  buttonLoading,
  buttonAdding,
  buttonUpdating,
  buttonSubmitting,
  buttonEdit,
  buttonDelete,
  buttonCancel,
  buttonShopNow,
  buttonBackTo,
`

const carouselStringsFragment = groq`
  carouselCounterText,
  carouselLeftArrowLabel,
  carouselRightArrowLabel,
  carouselDotLabel,
`

const cartStringsFragment = groq`
  cartTitle,
  cartClose,
  cartEmpty,
  cartVatSectionTitle,
  cartVatIdInputPlaceholder,
  cartVatIdError,
  cartInvalidVatIdError,
  cartCommentSectionTitle,
  cartCommentInputPlaceholder,
  cartDiscount,
  cartSubtotal,
  cartTotal,
  cartSubmit,
  cartAddToCartErrorMessage[] {
    ${simplePortableTextFragment}
  },
`

const formStringsFragment = groq`
  emailAddress,
  emailAddressPlaceholder,
  emailMissing,
  emailInvalid,
  firstName,
  firstNamePlaceholder,
  firstNameMissing,
  lastName,
  lastNamePlaceholder,
  lastNameMissing,
  fullName,
  fullNamePlaceholder,
  fullNameMissing,
  phoneNumber,
  phoneNumberPlaceholder,
  phoneNumberMissing,
  phoneNumberInvalid,
  company,
  companyPlaceholder,
  message,
  messagePlaceholder,
  productWaitlistSubmit,
  productWaitlistSuccess,
  productWaitlistError,
  searchPlaceholder,
`

const pageHeaderStringsFragment = groq`
  loadingPageTitle,
  languageSwitch,
  cartLink,
  skipToContent,
  goHomeLabel,
  mobileMenuLabel,
  searchLabel,
`

const collectionStringsFragment = groq`
  shopTitle,
  searchResultProducts,
  searchResultProductCount,
  searchResultProductsNotFound,
  searchResultShowAllProducts,
  searchingProducts,
  collectionProducts,
  collectionProductCount,
  collectionSortLabel,
  collectionSortDescription,
  collectionShowFiltersLabel,
  collectionFilters,
  collectionRemoveFilter,
  collectionClearAllLabel,
  collectionClearFiltersLabel,
  collectionFilterResultsLabel,
  collectionFilterNoResultsLabel,
  collectionFilterModalClose,
  collectionFilterNoResults[] {
    ${complexPortableTextFragment}
  },
`

const productStringsFragment = groq`
  productLowStock,
  productOutOfStock,
  productPriceLabel,
  productColorOptionLabel,
  productDiscountText,
  productIncreaseQuantity,
  productDecreaseQuantity,
  productEnterQuantity,
  productRelatedProducts,
`

const teamMemberStringsFragment = groq`
  teamMemberCardLinkedIn,
  teamMemberCardBlogArticles,
`

const siteStringsFragment = groq`
  ${blogStringsFragment}
  ${buttonStringsFragment}
  ${carouselStringsFragment}
  ${cartStringsFragment}
  ${formStringsFragment}
  ${pageHeaderStringsFragment}
  ${productStringsFragment}
  ${teamMemberStringsFragment}
  ${collectionStringsFragment}
`

const identityFragment = groq`
  logo {
    ${imageFragment}
  },
  logoSymbol {
    ${imageFragment}
  },
`

// Only settings that are not secret and can be used in the frontend
const publicSettingsFragment = groq`
  siteURL,
  pageAnimation,
  klaviyoAccountID,
  googleEvents,
  gtmContainerId,
  facebookEvents,
  facebookPixelId,
  facebookDomainVerification,
  shopifyEvents,
  cookieBotId,
  customHtml,
`

export const publicSiteSettingsQuery = groq`
  *[
    _type == "generalSettings" &&
    locale == $locale
  ][0] {
    ${publicSettingsFragment}
  }
`

export const allSiteSettingsQuery = groq`
  *[
    _type == "generalSettings" &&
    locale == $locale
  ][0]
`

export const siteFragment = groq`
  "generalSettings": *[
    _type == "generalSettings" &&
    locale == $locale
  ][0] {
    ${publicSettingsFragment}
  },
  "identitySettings": *[
    _type == "identitySettings" &&
    locale == $locale
  ][0] {
    ${identityFragment}
  },
  "cartSettings": *[
    _type == "cartSettings" &&
    locale == $locale
  ][0] {
    ${cartSettingsFragment}
  },
  "blogSettings": *[
    _type == "blogSettings" &&
    locale == $locale
  ][0],
  "cookieSettings": *[
    _type == "cookieSettings" &&
    locale == $locale
  ][0] {
    ${cookieConsentFragment}
  },
  "headerSettings": *[
    _type == "headerSettings" &&
    locale == $locale
  ][0] {
    ${headerFragment}
  },
  "footerSettings": *[
    _type == "footerSettings" &&
    locale == $locale
  ][0] {
    ${footerFragment}
  },
  "seoSettings": *[
    _type == "seoSettings" &&
    locale == $locale
  ][0],
  "schemaSettings": *[
    _type == "schemaSettings" &&
    locale == $locale
  ][0],
  "promoSettings": *[
    _type == "promoSettings" &&
    locale == $locale
  ][0] {
    ${headerPromoFragment}
  },
  "strings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${siteStringsFragment}
  },
  "publicLocales": *[
    _type == "generalSettings" &&
    public == true
  ][].locale,
  "productCounts": [{
    "slug": "all",
    "count": count(*[
      _type == "product" &&
      locale == $locale
    ]),
  }] + *[
    _type == "collection" &&
    locale == $locale
  ] {
    "slug": slug.current,
    "count": count(products),
  },
`

export const siteQuery = groq`
  {
    ${siteFragment}
  }
`
