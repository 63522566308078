import { useContext } from 'react'
import dynamic from 'next/dynamic'

import { type SanityModule } from '@data/sanity/queries/types/modules'
import { type SanityProductFragment } from '@data/sanity/queries/types/product'
import { StringsContext } from '@lib/strings-context'

const BlogPostBody = dynamic(() => import('./blog/blog-post-body'))
const BlogPostGrid = dynamic(() => import('./blog/blog-post-grid'))
const BlogPostHeader = dynamic(() => import('./blog/blog-post-header'))
const BlogPostItem = dynamic(() => import('./blog/blog-post-item'))
const BlogPostReadMore = dynamic(() => import('./blog/blog-post-read-more'))
const CartForm = dynamic(() => import('./shop/cart/form'))
const Collection = dynamic(() => import('./shop/collection'))
const DividerPhoto = dynamic(() => import('./divider-photo'))
const Grid = dynamic(() => import('./grid'))
const Hero = dynamic(() => import('./hero'))
const Marquee = dynamic(() => import('./marquee'))
const ProductHero = dynamic(() => import('./shop/product-hero'))
const RelatedProducts = dynamic(() => import('./shop/related-products'))
const SearchResultGrid = dynamic(() => import('./shop/search-result-grid'))
const SimpleGrid = dynamic(() => import('./simple-grid'))
const TeamMembersGrid = dynamic(() => import('./team-members-grid'))
const Video = dynamic(() => import('../components/video'))

interface ModuleProps {
  module: SanityModule
  collectionFeaturedProductIds?: number[]
  collectionProducts?: SanityProductFragment[]
}

const Module = ({
  module,
  collectionFeaturedProductIds,
  collectionProducts,
}: ModuleProps) => {
  const strings = useContext(StringsContext)
  switch (module._type) {
    case 'blogAuthorPostGrid': {
      const { title, author, posts } = module

      return <BlogPostGrid title={title} author={author} posts={posts} />
    }

    case 'blogCategoryPostGrid': {
      const { title, category, posts } = module

      return <BlogPostGrid title={title} category={category} posts={posts} />
    }

    case 'blogPostBody': {
      const { content } = module

      return <BlogPostBody content={content} />
    }

    case 'blogPostGrid': {
      const { title, posts } = module

      return <BlogPostGrid title={title} posts={posts} />
    }

    case 'blogPostHeader': {
      const { post } = module

      if (!post) {
        return null
      }

      return <BlogPostHeader post={post} />
    }

    case 'blogPostItem': {
      const { post } = module

      return <BlogPostItem post={post} />
    }

    case 'blogPostReadMore': {
      const { posts } = module

      return <BlogPostReadMore posts={posts} />
    }

    case 'cartForm': {
      const { active, cartSettings } = module

      if (!active || !cartSettings) {
        return null
      }

      return (
        <CartForm
          cartSettings={cartSettings}
          className="max-w-3xl mx-auto py-6"
        />
      )
    }

    case 'collectionGrid': {
      const { expandProducts, onlyDiscounts, collection, cartSettings, title } =
        module

      if (collectionProducts && collectionProducts.length > 0) {
        return (
          <Collection
            products={collectionProducts}
            expandProducts={expandProducts}
            onlyDiscounts={onlyDiscounts}
            featuredProductIds={collectionFeaturedProductIds ?? []}
            paginationLimit={cartSettings?.paginationLimit ?? 12}
            title={title ?? strings.shopTitle}
            sort={cartSettings?.sort}
            filter={cartSettings?.filter}
          />
        )
      }

      if (collection?.products && collection.products.length > 0) {
        return (
          <Collection
            products={collection.products}
            expandProducts={expandProducts}
            onlyDiscounts={onlyDiscounts}
            productIds={collection.productIds}
            featuredProductIds={collection.featuredProductIds ?? []}
            paginationLimit={cartSettings?.paginationLimit ?? 12}
            title={title ?? collection.title}
            sort={cartSettings?.sort}
            filter={cartSettings?.filter}
          />
        )
      }

      return null
    }

    case 'dividerPhoto': {
      const { photo } = module

      return <DividerPhoto photo={photo} />
    }

    case 'grid': {
      const {
        columns,
        size,
        spacing,
        noColumnGaps,
        noRowGaps,
        reverseSequence,
      } = module

      return (
        <Grid
          columns={columns}
          size={size}
          spacing={spacing}
          noColumnGaps={noColumnGaps}
          noRowGaps={noRowGaps}
          reverseSequence={reverseSequence}
        />
      )
    }

    case 'hero': {
      const {
        bgType,
        contentPosition,
        content,
        photos,
        lottieAssetUrl,
        muxVideo,
      } = module

      return (
        <Hero
          bgType={bgType}
          contentPosition={contentPosition}
          content={content}
          photos={photos}
          lottieAssetUrl={lottieAssetUrl}
          muxVideo={muxVideo}
          overlay
        />
      )
    }

    case 'marquee': {
      const { content, contentAlignment, items, speed, reverse, pausable } =
        module

      return (
        <Marquee
          content={content}
          contentAlignment={contentAlignment}
          items={items}
          speed={speed ?? 0.5}
          reverse={reverse}
          pausable={pausable}
        />
      )
    }

    case 'predefinedPageModule': {
      const { pageModules } = module

      return (
        <>
          {pageModules?.map((pageModule) => (
            <Module key={pageModule._key} module={pageModule} />
          ))}
        </>
      )
    }

    case 'productHero': {
      const { active, product, shoppingInfo } = module

      if (!active || !product) {
        return null
      }

      return <ProductHero product={product} shoppingInfo={shoppingInfo} />
    }

    case 'relatedProducts': {
      const { active, products, backupProducts } = module

      if (!active) {
        return null
      }

      return (
        <RelatedProducts products={products} backupProducts={backupProducts} />
      )
    }

    case 'searchResultGrid': {
      const { expandProducts, cartSettings } = module

      return (
        <SearchResultGrid
          expandProducts={expandProducts}
          paginationLimit={cartSettings?.paginationLimit ?? 8}
        />
      )
    }

    case 'simpleGrid': {
      const { blocks } = module

      return <SimpleGrid blocks={blocks} />
    }

    case 'teamMembersGrid': {
      const { title, teamMembers } = module

      return <TeamMembersGrid title={title} teamMembers={teamMembers} />
    }

    case 'video': {
      const { type, muxVideo, settings, aspectRatio } = module

      return (
        <Video
          type={type}
          muxVideo={muxVideo}
          settings={settings}
          aspectRatio={aspectRatio}
        />
      )
    }
  }
}

export default Module
